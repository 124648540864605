<template>
    <tHeaderAuth />

    <div
      class="bg-zinc-100 flex flex-col px-20 max-md:px-5"
    >
    <!-- justify-center items-center self-stretch -->
        <div
        class="items-center self-center flex w-full max-w-[1200px] flex-col my-6 
        max-md:max-w-full bg-white rounded-3xl shadow-md"
        >

            <div class="border-b-2 border-gray-200 w-full text-lg py-6 px-10">
                <div class="items-start flex flex-row gap-10 flex-wrap w-full">
                    <router-link class="tab" 
                    :to="{ name: 'Details' }"
                    :class="{ 'act': isActiveTab('Details') }"
                    >Details</router-link>
                    <router-link class="tab" 
                    :to="{ name: 'Inbox' }"
                    :class="{ 'act': isActiveTab('Inbox') }"
                    >Inbox <img src="/static/mail.svg" class="inline ml-1" width="18"></router-link>
                    <router-link class="tab" :to="{ name: 'MyKeys' }" :class="{ 'act': isActiveTab('MyKeys') }">Keys</router-link>
                    <router-link class="tab" :to="{ name: 'MyHold' }" :class="{ 'act': isActiveTab('MyHold') }">Holders</router-link>
                    <router-link class="tab ml-auto" :to="{ name: 'Settings' }" :class="{ 'act': isActiveTab('Settings') }">Settings</router-link>
                </div>
            </div>
                
            <router-view />
               
        </div>
    </div>

    <tFooter />
  </template>

<script setup>
import tHeaderAuth from '@/components/tHeaderAuth.vue'
import tFooter from '@/components/tFooter.vue'
</script>

<script>
export default {
  methods: {
    isActiveTab(tabName) {
      return this.$route.name === tabName;
    },
  },
};
</script>
