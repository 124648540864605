<template>
    <div
      class="justify-center items-center self-stretch bg-zinc-100 flex w-full flex-col px-20 py-12 max-md:max-w-full max-md:px-5"
    >
      <div
        class="justify-between items-start self-center flex w-full max-w-[1200px] gap-5 pr-6 max-md:max-w-full max-md:flex-wrap max-md:justify-center max-md:pr-5"
      >
        <router-link to="/" class="my-auto"><img
          loading="lazy"
          src="/static/logo.svg"
          class="aspect-[5.32] object-contain object-center w-[133px] overflow-hidden self-center max-w-full my-auto"
        /></router-link>
        <div
          class="items-start self-center flex w-[560px] max-w-full 
          justify-between gap-5 my-auto max-md:flex-wrap max-md:justify-center
          
          "
        >
          <router-link class="text-xl" to="/">What is it?</router-link>
          <router-link class="text-xl" to="/keys">Keys Market</router-link>
          <router-link class="text-xl" to="/holders">Keys Holders</router-link>
          <router-link class="text-xl" to="/mint">Mint Your Keys</router-link>
        </div>
        <div
          class="items-start self-stretch flex max-w-full justify-between gap-5"
        >
          
          <router-link class="text-xl whitespace-nowrap justify-center items-center border-gray-300 border-2 max-w-full px-8 py-4 rounded-lg max-md:px-5" to="/profile">Profile</router-link>
          <router-link class="text-xl flex flex-row gap-2 my-auto" to="/profile/inbox">
            <div>
            
              <img src="/static/mail.svg" class="inline fill-lime-700 mb-1" width=24>
            
            </div>
            <div><b>3</b></div>
          </router-link>

        </div>
      </div>
    </div>
  </template>