<template>
    
    <div class="bg-zinc-900 w-full h-screen flex flex-row overflow-x-hidden fixed">
        <!-- relative -->

        <div v-if="showPanel" class="fix group w-full md:w-12 md:hover:w-52 transition-all duration-200 fixed z-10 h-full bg-zinc-900 border-r border-zinc-800 overflow-hidden md:hover:shadow-2xl md:hover:shadow-black">
            <!-- Close icon-->
            <div class="w-12 h-12 p-2 rounded-full fixed bottom-4 right-4 md:hidden bg-sky-900">
                <button @click="showPanel = false"  class="p-1">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-6 w-6 text-sky-100"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>            
                </button>
            </div>

            <div class="p-1">
                <div class="w-52">
                    <div class="py-1 px-2">
                        <!-- <img src="/static/q.svg" alt="" class="h-6 w-6 fill-zinc-500"> -->
                        <div class="flex md:hidden">
                            <img src="/static/quota.svg" alt="" class="fill-zinc-500 h-5 mt-2">
                        </div>
                        <div class="hidden md:block">
                        <div class="hidden group-hover:flex h-6">
                            <img src="/static/quota.svg" alt="" class="fill-zinc-500 h-5 mt-1">
                        </div></div>
                        <div class="w-6 h-6 hidden md:flex group-hover:hidden">
                            <svg width="31" height="25" viewBox="0 0 31 25" xmlns="http://www.w3.org/2000/svg" class="fill-zinc-500">
                                <path d="M30.336 11.36C30.336 14.4533 29.2267 17.0027 27.008 19.008L30.336 24.64H23.072C22.112 24.64 21.1627 24.4587 20.224 24.096C19.3067 23.7333 18.5387 23.232 17.92 22.592C16.9173 22.6987 16 22.752 15.168 22.752C12.3093 22.752 9.728 22.3147 7.424 21.44C5.14133 20.5653 3.328 19.2747 1.984 17.568C0.661333 15.84 0 13.7707 0 11.36C0 8.97067 0.661333 6.92267 1.984 5.216C3.328 3.50933 5.152 2.21867 7.456 1.344C9.76 0.448 12.3307 0 15.168 0C18.0053 0 20.576 0.448 22.88 1.344C25.184 2.21867 26.9973 3.50933 28.32 5.216C29.664 6.92267 30.336 8.97067 30.336 11.36ZM15.168 20.864C16.0853 20.864 16.7573 20.4587 17.184 19.648C17.5467 19.0293 17.792 17.9307 17.92 16.352C17.984 15.52 18.016 13.856 18.016 11.36C18.016 9.184 17.9627 7.40267 17.856 6.016C17.792 4.736 17.5573 3.76533 17.152 3.104C16.7253 2.29333 16.064 1.888 15.168 1.888C14.2933 1.888 13.632 2.29333 13.184 3.104C12.8213 3.744 12.5867 4.71467 12.48 6.016C12.3733 7.06133 12.32 8.84267 12.32 11.36C12.32 13.9413 12.3627 15.6053 12.448 16.352C12.5547 17.9093 12.7893 19.008 13.152 19.648C13.6 20.4587 14.272 20.864 15.168 20.864Z" />
                            </svg>
                        </div>                        
                    </div>
                </div>
            </div>
            <!-- Menu items -->
            <div class="p-1">
                <div v-for="(item, index) in menuItems" :key="index" class="w-52">
                    <!-- :class="{ 'act': isActiveTab( item.name ) }" -->
                    <router-link 
                        :to="item.goto"
                        v-if="index < 5" class="flex items-center gap-3 hover:bg-zinc-800 py-2 px-2">
                        <img :src="item.icon" alt="" class="h-6 w-6">
                        <span class="text-zinc-300">{{ item.text }}</span>
                    </router-link>
                </div>
            </div>
            <!-- 6th item -->
            <div class="absolute bottom-1 left-1 right-1 w-52">
                <div class="flex items-center gap-3 hover:bg-zinc-800 py-2 px-2">
                    <img :src="menuItems[5].icon" alt="" class="h-6 w-6">
                    <span class="text-zinc-300">{{ menuItems[5].text }}</span>
                </div>
            </div>
            <!-- Rest of your code -->
            
        </div>
        <div class="ml-0 md:ml-12 w-full text-zinc-200 text-left">

            <router-view /> 

        </div>
        <!-- Open Icon 
        <div class="w-12 h-12 p-2 rounded-full fixed bottom-4 right-4 md:hidden bg-sky-900 shadow-lg shadow-black">
            <button @click="showPanel = true" class="p-1">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-6 w-6 text-sky-100"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
            </button>
        </div>-->
    </div>


    <div v-if="!showPanel" class="fix h-20 border-t border-zinc-800 text-sm font-mono justify-between p-2 pl-5 text-zinc-400 flex flex-row fixed bottom-0 left-0 right-0 bg-zinc-900 md:hidden">
        <div v-for="(item, index) in menuItems" :key="index" class="">
            <!-- :class="{ 'act': isActiveTab( item.name ) }" -->
            <router-link 
                    :to="item.goto"
                    v-if="index < 4" 
                    class="flex items-center grow gap-2 hover:bg-zinc-800 py-1 px-2 text-center flex-col rounded-xl"
                >
                    <div><img :src="item.icon" alt="" class="h-s w-6"></div>
                    <div class="text-zinc-300">{{ item.text }}</div>
            </router-link>
            <div v-if="index == 4" class="w-12 h-12 p-2 rounded-full md:hidden bg-sky-900 shadow-lg shadow-black">
                <button @click="showPanel = true" class="p-1">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-6 w-6 text-sky-100"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
                </button>
            </div>
        </div>
     </div>

</template>

<script>
export default {
    data() {
        return {
            showPanel: window.innerWidth <= 768 ? false : true,
            menuItems: [
                { icon: '/static/s.svg', goto: '/dash', text: 'Keys' },
                { icon: '/static/s.svg', goto: '/dash/user', text: 'Feed' },
                { icon: '/static/s.svg', goto: '/dash', text: 'Messages' },
                { icon: '/static/s.svg', goto: '/dash', text: 'Holders' },
                { icon: '/static/s.svg', goto: '/dash', text: 'Collect' },
                { icon: '/static/online.svg', goto: '/dash', text: 'Profile' },
            ]
        }
    },
    methods: {
    isActiveTab(tabName) {
      return this.$route.name === tabName;
    },
  },
}
</script>