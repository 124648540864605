import { createApp } from 'vue'
import App from './App.vue'

//import router from './router'
//router/index.js start:
import { createRouter, createWebHistory } from 'vue-router'

import Cookies from 'js-cookie';

import Home from '@/views/Home.vue'
import Profile from '@/views/Profile.vue'
import Dash from '@/views/Dash.vue'
import Keys from '@/views/Keys.vue'
import Keys1 from '@/views/Keys1.vue'

const routes = [
  {
    path: '/allow',
    name: 'allow', 
    component: () => import('@/views/Allow.vue') 
  },
  {
    path: '/allownot',
    name: 'allownot', 
    component: () => import('@/views/AllowNot.vue') 
  },

  {
    path: '/',
    name: 'home',
    component: Home
  },

  { path: '/keys', name: 'keys', component: Keys },
  {
    path: '/keys/:userId', 
    component: Keys1,
  },

  { path: '/dash', name: 'dash', component: () => import('@/views/Dash.vue') },
  { path: '/holders', name: 'holders', component: () => import('@/views/Holders.vue') },
  { path: '/mint', name: 'mint', component: () => import('@/views/Mint.vue') },

  { path: '/signup', name: 'signup', component: () => import('@/views/SignUp.vue') },
  { path: '/signup1', name: 'signup1', component: () => import('@/views/SignUp1.vue') },
  { path: '/signup2', name: 'signup2', component: () => import('@/views/SignUp2.vue') },
  { path: '/signup3', name: 'signup3', component: () => import('@/views/SignUp3.vue') },
  { path: '/signin', name: 'signin', component: () => import('@/views/SignIn.vue') },

  {
    path: '/dash',
    name: 'dash',
    component: Dash,
      children: [
        { path: '', name: 'dashKeys', component: () => import('@/views/DashKeys.vue') },
        { path: 'user', name: 'dashUser', component: () => import('@/views/DashUser.vue') },
      ]
  },

  {
    path: '/profile',
    name: 'profile',
    component: Profile,
      children: [
        { path: '', name: 'Details', component: () => import('@/views/ProfileDetails.vue') },
        { path: 'inbox', name: 'Inbox', component: () => import('@/views/ProfileInbox.vue') },
        { path: 'mykeys', name: 'MyKeys', component: () => import('@/views/ProfileKeys.vue') },
        { path: 'myhold', name: 'MyHold', component: () => import('@/views/ProfileHolds.vue') },
        { path: 'settings', name: 'Settings', component: () => import('@/views/ProfileSettings.vue') },
      ]
  },

  {
    path: '/:catchAll(.*)*',
    name: "Error",
    component: Error,
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
// router/index.js end

router.beforeEach((to, from, next) => {
  const myCookie = Cookies.get('myCookie');
  if (to.path !== '/allownot' && to.path !== '/allow' && !myCookie) {
    next('/allownot');
  } else {
    next();
  } 
})

createApp(App).use(router).mount('#app')
