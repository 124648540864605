<template>
    <div
      class="justify-center items-center  bg-zinc-100 flex w-full flex-col pt-20 pb-24 px-20 max-md:max-w-full max-md:px-5"
    >
      <div
        class="self-center flex w-full max-w-[1240px] flex-col max-md:max-w-full"
      >
        <div
          class="justify-center self-center w-full max-w-[1175px] pr-6 py-6 max-md:max-w-full max-md:pr-5"
        >
          <div
            class="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0"
          >
            <div
              class="flex flex-col items-stretch w-[15%] max-md:w-full max-md:ml-0"
            >
              <img
                loading="lazy"
                src="/static/logo.svg"
                class="aspect-[5.32] object-contain object-center w-[133px] overflow-hidden max-w-full max-md:mt-10"
              />
            </div>
            <div
              class="flex flex-col items-stretch w-[85%] ml-5 max-md:w-full max-md:ml-0"
            >
              <div class="max-md:max-w-full max-md:mt-10">
                <div
                  class="gap-5 flex max-md:flex-col max-md:items-stretch max-md:gap-0"
                >
                  <div
                    class="flex flex-col items-stretch w-3/12 max-md:w-full max-md:ml-0"
                  >
                    <div
                      class="justify-center items-start flex grow flex-col max-md:mt-10"
                    >
                      <div
                        class=" text-neutral-700  font-bold"
                      >
                        PRODUCT
                      </div>
                      <div class=" text-neutral-700  mt-2.5">
                        Keys Market
                      </div>
                      <div class=" text-neutral-700  mt-2.5">
                        Keys Holders
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex flex-col items-stretch w-3/12 ml-5 max-md:w-full max-md:ml-0"
                  >
                    <div
                      class="justify-center items-start flex grow flex-col max-md:mt-10"
                    >
                      <div
                        class=" text-neutral-700  font-bold whitespace-nowrap"
                      >
                        RESOURCES
                      </div>
                      <div class=" text-neutral-700  mt-2.5">
                        What it is?
                      </div>
                      <div class=" text-neutral-700  mt-2.5">
                        Mint Your Keys
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex flex-col items-stretch w-3/12 ml-5 max-md:w-full max-md:ml-0"
                  >
                    <div
                      class="justify-center items-start flex grow flex-col max-md:mt-10"
                    >
                      <div
                        class=" text-neutral-700  font-bold"
                      >
                        SOCIAL
                      </div>
                      <div class=" text-neutral-700  mt-2.5">
                        Twitter
                      </div>
                      <div class=" text-neutral-700  mt-2.5">
                        Facebook
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex flex-col items-stretch w-3/12 ml-5 max-md:w-full max-md:ml-0"
                  >
                    <div
                      class="justify-center items-start flex grow flex-col max-md:mt-10"
                    >
                      <div
                        class=" text-neutral-700  font-bold"
                      >
                        RESOURCES
                      </div>
                      <div class=" text-neutral-700  mt-2.5">
                        Guide
                      </div>
                      <div class=" text-neutral-700  mt-2.5">
                        Tutortials
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="bg-neutral-400  w-full h-px mt-9 max-md:max-w-full"
        ></div>
        <div
          class="items-start flex mt-8 gap-5 self-start"
        >
          <div class="text-neutral-700 text-sm flex-1">Terms & Conditions</div>
          <div class="text-neutral-700 text-sm  whitespace-nowrap">Privacy Policy</div>
        </div>
      </div>
    </div>
  </template>